import React, { useEffect, useState } from "react";
import { Link } from "gatsby"

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Chat } from "../components/chat";
import { Loading } from "../components/loading";
import { ErrorComponent } from "../components/errorComponent";
import { loginWebSockets, newSubscriber, sendNotification } from "../utils/customApiCall";
import { getUserEmail } from "../utils/profileService"

import Paper from '@mui/material/Paper';

const ChatContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [pubsubLogin, setPubsubLoginInfo] = useState(null);

  useEffect(() => {
      if (account && !pubsubLogin && inProgress === InteractionStatus.None) {
          const request = {
              ...apiRequest,
              account: account
          };
          instance.acquireTokenSilent(request).then((response) => {
            window.localStorage.setItem("accessToken", JSON.stringify(response.accessToken));
            window.localStorage.setItem("authClaims", JSON.stringify(response.account.idTokenClaims));
            let userName = getUserEmail();
            loginWebSockets(response.accessToken, userName).then(response => setPubsubLoginInfo(response));
          }).catch((e) => {
              if (e instanceof InteractionRequiredAuthError) {
                  instance.acquireTokenRedirect(request);
              }
          });
      }
  }, [account, inProgress, instance, pubsubLogin]);

  return (
      <Paper>
          { pubsubLogin ? <Chat pubsubLogin={pubsubLogin} /> : null }
      </Paper>
  );
};

const ChatPage = () => {
  const authRequest = {
      ...apiRequest
  };

  return (
      <Layout>
        <Seo title="Chat" />
        <h1>Chat</h1>
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
            errorComponent={ErrorComponent} 
            loadingComponent={Loading}
        >
            <ChatContent />
        </MsalAuthenticationTemplate>
        <Link to="/">Go back to the homepage</Link>
      </Layout>
    )
};

export default ChatPage
