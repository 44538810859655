import React, { useState } from "react"

import Paper from '@mui/material/Paper'
import { getUserEmail } from "../utils/profileService";

/**
 * Renders Chat
 * @param props 
 */
export const Chat = (props) => {
    const [webSocket, setWebSocket] = useState(null);
    const [messages, addMessage] = useState([]);

    function getWebSocket() {
        let ws = new WebSocket(props.pubsubLogin.url);
        ws.onopen = () => console.log('connected');

        ws.onmessage = event => {
            let data = JSON.parse(event.data);
            let m = `[${data.from || ''}] ${data.content}`;
            addMessage(messages => messages.concat([m,]));
        };

        ws.onclose = () => console.log('disconnected');
        
        return ws;
    }

    let ws = webSocket;
    if (ws === null) {
        ws = getWebSocket();
        setWebSocket(ws);
    }

    return (
        <Paper>
            <input id="message" type="text" size="10" onKeyDown={(e) => {
                if (e.code !== "Enter") return;
                
                ws.send(`{"from":"${getUserEmail()}","content":"${e.currentTarget.value}"}`);
                e.currentTarget.value = '';
            }} />
            {messages.length > 0 ? messages.map((message, index) => (
                <p key={`push_${index}`}>{message}</p>
            )) : null}
        </Paper>
    );
}
